import { Box, Breadcrumbs, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ButtonRegister from "../../../Components/ButtonRegister";
import { useTranslation } from "react-i18next";

const CourseIntroduction = ({ title, subTitle, logo }) => {
  const { t } = useTranslation();
  return (
    <div>
      <section className="container mx-auto px-2 flex flex-col-reverse sm:flex-row justify-between  items-start gap-5">
        <aside className="font-main">
          <Breadcrumbs
            className="hidden sm:block"
            sx={{ fontFamily: "Montserrat, sans-serif" }}
            separator={
              <NavigateNextIcon fontSize="small" sx={{ color: "#0284C7" }} />
            }
            aria-label="breadcrumb"
          >
            <Link to="/">
              <span className="text-[#0284C7] text-[16px] font-semibold">
                {t("footer.home")}
              </span>
            </Link>
            <Link to="/">
              <span className="text-[#0284C7] text-[16px] font-semibold">
                {t("courses.header.header")}
              </span>
            </Link>

            <Typography sx={{ fontFamily: "Montserrat, sans-serif" }}>
              <span className="text-[#94A3B8] text-[16px] font-semibold">
                {title}
              </span>
            </Typography>
          </Breadcrumbs>
          <Box
            component={"div"}
            sx={{
              color: "#262626",
              fontFamily: "Montserrat, sans-serif",
              mt: "10px",
              maxWidth: "800px",
            }}
          >
            <h1 className="text-[60px] dark:text-[#FAFAFA] font-[600]">
              {" "}
              {title}
            </h1>
            <p className="text-[16px] dark:text-[#FAFAFA] font-normal">
              {subTitle}
            </p>
            <div
              className="mt-[28px]"
              onClick={() => {
                window.scroll({
                  top: 4130,
                  left: 0,
                  behavior: "smooth",
                });
              }}
            >
              <ButtonRegister text={t("courses.header.signup-button")} />
            </div>
          </Box>
        </aside>
        <aside className="relative">
          <Breadcrumbs
            className="sm:hidden"
            sx={{ fontFamily: "Montserrat, sans-serif" }}
            separator={
              <NavigateNextIcon fontSize="small" sx={{ color: "#0284C7" }} />
            }
            aria-label="breadcrumb"
          >
            <Link to="/">
              <span className="text-[#0284C7] text-[16px] font-semibold">
                {t("footer.home")}
              </span>
            </Link>
            <Link to="/">
              <span className="text-[#0284C7] text-[16px] font-semibold">
                {t("courses.header.header")}
              </span>
            </Link>

            <Typography sx={{ fontFamily: "Montserrat, sans-serif" }}>
              <span className="text-[#94A3B8] text-[16px] font-semibold">
                {title}
              </span>
            </Typography>
          </Breadcrumbs>
          <div className="object-cover max-w-[320px] max-h-[320px]">
            <img
              src={`${process.env.REACT_APP_ASSETS_URL}${logo}`}
              alt="logo"
            />
          </div>
          <div className="rotate-180 absolute bottom-[-220px] left-[30px] max-w-[280px] blur-sm h-[280px] sm:bottom-[-110px] md:bottom-[-140px] lg:bottom-[-210px]">
            <img
              className="opacity-[0.05] "
              src={`${process.env.REACT_APP_ASSETS_URL}${logo}`}
              alt="logo"
            />
          </div>
        </aside>
      </section>
    </div>
  );
};

export default CourseIntroduction;
