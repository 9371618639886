
// eslint-disable-next-line react/prop-types
function RightOutlined({ fill, fill2, svgStyle }) {
    return (
        <svg style={svgStyle} width="32" height="32" viewBox="0 0 32 32" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M12.3866 21.1733L17.5599 16L12.3866 10.8267C11.8666 10.3067 11.8666 9.46667 12.3866 8.94667C12.9066 8.42667 13.7466 8.42667 14.2666 8.94667L20.3866 15.0667C20.9066 15.5867 20.9066 16.4267 20.3866 16.9467L14.2666 23.0667C13.7466 23.5867 12.9066 23.5867 12.3866 23.0667C11.8799 22.5467 11.8666 21.6933 12.3866 21.1733Z" fill={fill2} />
        </svg>

    )
}

export default RightOutlined