import React from "react";
import { DarkModeSwitch } from "react-toggle-dark-mode";
export default function Switcher({ darkSide, toggleDarkMode }) {
  return (
    <>
      <div className=" flex flex-col items-center mr-5">
        <DarkModeSwitch
          checked={darkSide}
          onChange={toggleDarkMode}
          size={26}
          sunColor="#0284C7"
        />
      </div>
    </>
  );
}
