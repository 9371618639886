import React from "react";
// import Language from './Language'
// import Dropdown from '../Navbar/Dropdown'
function NavItem(props) {
  const { content, href, className } = props;
  return (
    <li className={className}>
      <p href={href}>{content}</p>
    </li>
  );
}

export default NavItem;
