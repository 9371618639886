import { lazy } from "react";
export const HomePage = lazy(() => import("../Containers/Home/Home.jsx"));


export const Blogs = lazy(() => import("../Containers/Blogs/Blogs.jsx"));
export const BlogItemPage = lazy(() =>
  import("../Containers/Blogs/BlogsItem/BlogItemPage.jsx")
);
export const AboutUs = lazy(() => import("../Containers/AboutUs/AboutUs.jsx"));

