import React from "react";
import Alif from "../images/ALIF.svg";
import dc from "../images/DCITY.svg";
import eskhata from "../images/ESKHATA.svg";
import esPay from "../images/esPay.svg";
import humo from "../images/HUMO.svg";
import livo from "../images/LIVO.svg";
import vatanICT from "../images/VATANICT.svg";
import amonat from "../images/amonat.svg";
import oriyon from "../images/oriyon.svg";
import payvand from "../images/payvand.svg";
import rowtech from "../images/rowtech.svg";
import vazorat from "../images/vazorat.svg";
import rebus from "../images/rebus.svg";
import tecnohub from "../images/tecnohub.svg";

const data = [
  {
    img: amonat,
  },
  {
    img: oriyon,
  },
  {
    img: Alif,
  },
  {
    img: humo,
  },
  {
    img: payvand,
  },
  {
    img: rowtech,
  },
  {
    img: dc,
  },
  {
    img: vatanICT,
  },
  {
    img: vazorat,
  },
  {
    img: livo,
  },
  {
    img: rebus,
  },
  {
    img: tecnohub,
  },
  {
    img: eskhata,
  },
  {
    img: esPay,
  },
];
const WorkPlace = ({ text, show }) => {
  return (
    <div className="">
      {show && <p className="header-text px-5 mb-7">{text}</p>}
      <div
        x-data="{}"
        x-init="$nextTick(() => {
        let ul = $refs.logos;
        ul.insertAdjacentHTML('afterend', ul.outerHTML);
        ul.nextSibling.setAttribute('aria-hidden', 'true');
    })"
        className="w-full inline-flex flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-128px),transparent_100%)]"
      >
        <ul
          x-ref="logos"
          className="flex items-center justify-center md:justify-start [&_li]:mx-3 [&_img]:max-w-none animate-infinite-scroll"
        >
          {data.length > 0 &&
            data.map((elem, i) => {
              return (
                <li key={i}>
                  <img
                    loading="lazy"
                    width="auto"
                    height="auto"
                    src={elem.img}
                    alt="work"
                  />
                </li>
              );
            })}
        </ul>
        <ul
          x-ref="logos"
          className="flex items-center justify-center md:justify-start [&_li]:mx-3 [&_img]:max-w-none animate-infinite-scroll"
        >
          {data.length > 0 &&
            data.map((elem, i) => {
              return (
                <li key={i}>
                  <img
                    title="programing"
                    loading="lazy"
                    width="auto"
                    height="auto"
                    src={elem.img}
                    alt="work"
                  />
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default WorkPlace;
