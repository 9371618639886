import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const blog = createApi({
  reducerPath: "blog",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),
  tagTypes: ["blog"],
  endpoints: (builder) => ({
    blog: builder.query({
      query: ({ lang }) => `Posts/GetAllPosts?Language=${lang}`,
      providesTags: ["blog"],
    }),
    blogById: builder.query({
      query: ({ id, lang }) =>
        `Posts/GetPostById?PostId=${id}&Language=${lang}`,
      providesTags: ["blog"],
    }),
    Addlike: builder.mutation({
      query: ({ postId, bool }) => ({
        url: `Posts/AddLike?PostId=${postId}&Liked=${bool}`,
        method: "PUT",

        headers: { accept: "application/json" },
      }),
      invalidatesTags: ["blog"],
    }),
    AddDislike: builder.mutation({
      query: ({ postId, bool }) => ({
        url: `Posts/AddDisLike?PostId=${postId}&Disliked=${bool}`,
        method: "PUT",
        headers: { accept: "application/json" },
      }),
      invalidatesTags: ["blog"],
    }),
    PostComment: builder.mutation({
      query: (body) => ({
        url: `Posts/AddComment`,
        method: "PUT",
        headers: { accept: "application/json" },
        body: body,
      }),

      invalidatesTags: ["blog"],
    }),
  }),
});

export const {
  useBlogQuery,
  useBlogByIdQuery,
  useAddlikeMutation,
  useAddDislikeMutation,
  usePostCommentMutation,
} = blog;
