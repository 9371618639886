import Picture2 from "../../Containers/Reviews/image 202 (1).svg";
import Picture1 from "../../Containers/Reviews/image 202.svg";

const ReviewCard = ({ text, image, reviewerName, courseName }) => {
  return (
    <div className="flex gap-4 flex-col-reverse  font-main items-start text-[#262626] lg:h-[192px] lg:max-h-[192px] lg:flex-row-reverse lg:gap-[24px]">
      <div className=" bg-white dark:bg-[#161F2D] lg:h-[192px] w-[-webkit-fill-available]  lg:max-h-[192px] rounded-[20px] p-[24px] ">
        <div className=" flex  gap-[20px]">
          <div className="w-[200px]">
            <img className="" src={Picture1} alt="" />
          </div>
          <div>
            <h1 className=" text-[16px] text-[#262626] dark:text-[#FAFAFAFA] font-medium">{text}</h1>
          </div>
          <div className="flex items-end w-[200px]">
            <img src={Picture2} alt="" />
          </div>
        </div>
      </div>
      <div className="flex flex-col container  lg:w-[310px] items-start justify-around dark:bg-[#161F2D] p-6 rounded-2xl bg-white ">
        <img
          src={image}
          alt={reviewerName}
          className="w-20 h-20  rounded-full"
        />
        <div className="mt-4">
          <h3 className="text-lg font-semibold dark:text-[#FAFAFAFA] text-gray-900">
            {reviewerName}
          </h3>
          <p className="text-sm font-medium text-[#0284C7]">{courseName}</p>
        </div>
      </div>
    </div>
  );
};

export default ReviewCard;
