import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const course = createApi({
  reducerPath: "course",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),
  tagTypes: ["course"],
  endpoints: (builder) => ({
    getCourseByLanguage: builder.query({
      query: (lang) => `Course/GetAllCourses?Language=${lang}`,
      providesTags: ["course"],
    }),
    getCourseById: builder.query({
      query: ({ id, lang }) =>
        `Course/GetCourseById?CourseId=${id}&Language=${lang}`,
      invalidatesTags: ["courseById"],
    }),
    getCourseCategoryByLanguage: builder.query({
      query: (lang) => `course-categories?Language=${lang}`,
      invalidatesTags: ["courseCategoryByLanguage"],
    }),
    getCourseByCategory: builder.query({
      query: ({ id, lang }) =>
        `Course/GetAllCourses?Language=${lang}&CategoryId=${id}`,
      invalidatesTags: ["courseByCategory"],
    }),
  }),
});

export const {
  useGetCourseCategoryByLanguageQuery,
  useGetCourseByCategoryQuery,
  useGetCourseByLanguageQuery,
  useGetCourseByIdQuery,
} = course;
