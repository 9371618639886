// eslint-disable-next-line react/prop-types
function LeftOutlined({ fill, fill2, svgStyle }) {
    return (
        <svg style={svgStyle} width="32" height="32" viewBox="0 0 32 32" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M19.6134 21.1733L14.4401 16L19.6134 10.8267C20.1334 10.3067 20.1334 9.46667 19.6134 8.94667C19.0934 8.42667 18.2534 8.42667 17.7334 8.94667L11.6134 15.0667C11.0934 15.5867 11.0934 16.4267 11.6134 16.9467L17.7334 23.0667C18.2534 23.5867 19.0934 23.5867 19.6134 23.0667C20.1201 22.5467 20.1334 21.6933 19.6134 21.1733Z" fill={fill2} />
        </svg>

    )
}

export default LeftOutlined