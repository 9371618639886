import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
export const form = createApi({
  reducerPath: "form",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL2,
  }),
  tagTypes: ["lead"],
  endpoints: (builder) => ({
    registerLeadTo: builder.mutation({
      query: (lead) => ({
        url: `Marketing/register`,
        method: "POST",
        body: lead,
      }),
      invalidatesTags: ["lead"],
    }),
  }),
});

export const { useRegisterLeadToMutation } = form;
