import DesktopVersion from "./Adaptive/DesktopVersion";
import LaptopVersion from "./Adaptive/LaptopVersion";
import MobileVersion from "./Adaptive/MobileVersion";

function Navbar({ darkSide, toggleDarkMode, setLang, lang }) {
  return (
    <>
      <div className="xl:block hidden">
        <DesktopVersion
          darkSide={darkSide}
          toggleDarkMode={toggleDarkMode}
          setLang={setLang}
          lang={lang}
        />
      </div>
      <div className="sm:block hidden xl:hidden">
        <LaptopVersion
          darkSide={darkSide}
          toggleDarkMode={toggleDarkMode}
          setLang={setLang}
          lang={lang}
        />
      </div>
      <div className="sm:hidden">
        <MobileVersion
          darkSide={darkSide}
          toggleDarkMode={toggleDarkMode}
          setLang={setLang}
          lang={lang}
        />
      </div>
    </>
  );
}

export default Navbar;
