import { Paper } from "@mui/material";
import AccessTimeFilledRoundedIcon from "@mui/icons-material/AccessTimeFilledRounded";
import AssignmentRoundedIcon from "@mui/icons-material/AssignmentRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import ChairRoundedIcon from "@mui/icons-material/ChairRounded";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import WorkspacePremiumRoundedIcon from "@mui/icons-material/WorkspacePremiumRounded";
import { useTranslation } from "react-i18next";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const CourseDuration = ({
  startDate,
  duration,
  durationType,
  teachingFormat,
  neededStudents,
  description,
  dark,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <section className="container relative z-10 px-2 mx-auto mt-[80px] ">
        <aside className="flex flex-wrap sm:grid sm:grid-cols-2 lg:flex lg:flex-nowrap items-center gap-3 text-center justify-between">
          <Paper
            elevation={0}
            sx={{
              fontFamily: "Montserrat, sans-serif",
              fontSize: "14px",
              padding: "16px 24px 16px 20px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
              fontWeight: "500",
              borderRadius: "16px",
              color: dark === "dark" ? "#262626" : "white",
              bgcolor: dark === "dark" ? "white" : "#161F2D",
            }}
          >
            <span>
              <CalendarMonthRoundedIcon />
            </span>
            {`${t("courses.courses.start")}: ${new Date(startDate).getDate()} ${
              months[new Date(startDate).getMonth()]
            }`}
          </Paper>
          <Paper
            elevation={0}
            sx={{
              fontFamily: "Montserrat, sans-serif",
              fontSize: "14px",
              padding: "16px 24px 16px 20px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
              fontWeight: "500",
              borderRadius: "16px",
              color: dark === "dark" ? "#262626" : "white",
              bgcolor: dark === "dark" ? "white" : "#161F2D",
            }}
          >
            <span>
              <AccessTimeFilledRoundedIcon />
            </span>
            {`${t("courses.courses.duration")}: ${duration} ${
              durationType === 3
                ? t("courses.courses.weeks.month")
                : durationType === 2
                ? t("courses.courses.weeks.week")
                : durationType === 1
                ? t("courses.courses.weeks.day")
                : t("courses.courses.weeks.year")
            }`}
          </Paper>
          <Paper
            elevation={0}
            sx={{
              fontFamily: "Montserrat, sans-serif",
              fontSize: "14px",
              padding: "16px 24px 16px 20px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
              fontWeight: "500",
              borderRadius: "16px",
              color: dark === "dark" ? "#262626" : "white",
              bgcolor: dark === "dark" ? "white" : "#161F2D",
            }}
          >
            <span>
              <AssignmentRoundedIcon />
            </span>
            {t("courses.courses.lesson")}
          </Paper>
          <Paper
            elevation={0}
            sx={{
              fontFamily: "Montserrat, sans-serif",
              fontSize: "14px",
              padding: "16px 24px 16px 20px",
              width: "100%",
              fontWeight: "500",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
              borderRadius: "16px",
              color: dark === "dark" ? "#262626" : "white",
              bgcolor: dark === "dark" ? "white" : "#161F2D",
            }}
          >
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M17.7028 12.1171H10.846C11.624 12.0615 12.3454 11.767 12.8603 11.295C13.3752 10.823 13.644 10.2097 13.6109 9.58255C13.5778 8.9554 13.2453 8.36262 12.6824 7.92736C12.1196 7.49211 11.3698 7.24786 10.5887 7.24536H8.34883C8.68369 6.88351 8.9232 6.47057 9.05271 6.03179C9.18221 5.59302 9.199 5.13763 9.10204 4.69346C9.00507 4.2493 8.7964 3.82569 8.48878 3.44855C8.18115 3.0714 7.78104 2.74864 7.3129 2.5L17.7028 2.5C18.1797 2.50057 18.6368 2.65321 18.9738 2.92441C19.3107 3.1956 19.5 3.56318 19.5 3.94643V10.6739C19.5 11.4711 18.6947 12.1182 17.7028 12.1182V12.1171ZM5.03174 7.2475C5.36003 7.25364 5.68653 7.20698 5.99205 7.11026C6.29758 7.01354 6.57597 6.86871 6.81085 6.6843C7.04573 6.49988 7.23237 6.27959 7.3598 6.03637C7.48722 5.79316 7.55287 5.53193 7.55287 5.26804C7.55287 5.00414 7.48722 4.74291 7.3598 4.4997C7.23237 4.25648 7.04573 4.03619 6.81085 3.85177C6.57597 3.66736 6.29758 3.52253 5.99205 3.42581C5.68653 3.32909 5.36003 3.28243 5.03174 3.28857C4.38839 3.3006 3.77643 3.51443 3.32672 3.88434C2.87701 4.25425 2.62523 4.75088 2.62523 5.26804C2.62523 5.78519 2.87701 6.28182 3.32672 6.65173C3.77643 7.02164 4.38839 7.23547 5.03174 7.2475ZM11.9579 9.68607C11.9579 9.0775 11.3446 8.58464 10.5887 8.58464H5.03307C4.56905 8.5845 4.10955 8.65785 3.68082 8.80048C3.25209 8.94312 2.86253 9.15226 2.53442 9.41594C2.20631 9.67962 1.94607 9.99267 1.76858 10.3372C1.59109 10.6818 1.49983 11.051 1.5 11.4239V12.715C1.5 13.285 2.07596 13.7468 2.78524 13.7468H3.01455L3.39053 16.5432C3.42569 16.8058 3.58027 17.0487 3.82461 17.2252C4.06894 17.4018 4.38582 17.4997 4.71443 17.5H5.38371C5.70534 17.4999 6.01607 17.4063 6.25863 17.2366C6.50119 17.0669 6.65923 16.8324 6.70361 16.5764L7.70754 10.7864H10.5873C11.3433 10.7864 11.9566 10.2936 11.9566 9.68607H11.9579Z"
                  fill="#262626"
                />
              </svg>
            </span>
            {`${t("courses.courses.form")}: ${teachingFormat}`}
          </Paper>
        </aside>
        <aside className="flex flex-wrap items-center sm:grid sm:grid-cols-2 lg:flex lg:flex-nowrap  gap-3 text-center justify-between lg:px-[92.5px] my-3">
          <Paper
            elevation={0}
            sx={{
              fontFamily: "Montserrat, sans-serif",
              fontSize: "14px",
              padding: "16px 24px 16px 20px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
              fontWeight: "500",
              borderRadius: "16px",
              color: dark === "dark" ? "#262626" : "white",
              bgcolor: dark === "dark" ? "white" : "#161F2D",
            }}
          >
            <span>
              <GroupsRoundedIcon />
            </span>
            {`${t("courses.courses.group")}`}
          </Paper>{" "}
          <Paper
            elevation={0}
            sx={{
              fontFamily: "Montserrat, sans-serif",
              fontSize: "14px",
              padding: "16px 24px 16px 20px",
              width: "100%",
              fontWeight: "500",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
              borderRadius: "16px",
              color: dark === "dark" ? "#262626" : "white",
              bgcolor: dark === "dark" ? "white" : "#161F2D",
            }}
          >
            <span>
              <ChairRoundedIcon />
            </span>
            {`${t("courses.courses.place")}: ${neededStudents}`}
          </Paper>{" "}
          <Paper
            elevation={0}
            className="sm:col-span-2 lg:col-span-1"
            sx={{
              fontFamily: "Montserrat, sans-serif",
              fontSize: "14px",
              padding: "16px 24px 16px 20px",
              width: "100%",
              fontWeight: "500",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
              borderRadius: "16px",
              color: dark === "dark" ? "#262626" : "white",
              bgcolor: dark === "dark" ? "white" : "#161F2D",
            }}
          >
            <span>
              <WorkspacePremiumRoundedIcon />
            </span>
            {t("courses.courses.certificate")}
          </Paper>
        </aside>
        <main>
          <Paper
            elevation={0}
            sx={{
              py: "24px",
              px: "40px",
              borderRadius: "16px",
              color: dark === "dark" ? "#262626" : "white",
              bgcolor: dark === "dark" ? "white" : "#161F2D",
            }}
          >
            <p dangerouslySetInnerHTML={{ __html: description }} />
          </Paper>
        </main>
      </section>
    </div>
  );
};

export default CourseDuration;
