import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Navbarf from "../Containers/Navbar/Navbar";
import Footer from "../Containers/Footer/Footer";
import useDarkside from "../Hooks/useDarkSide";
export default function OwnLayaout() {
  const language = localStorage.getItem("i18nextLng");
  const [lang, setLang] = useState(language || "rus");
  localStorage.setItem("i18nextLng", lang);
  const [colorTheme, setTheme] = useDarkside();
  const [darkSide, setDarkSide] = useState(
    colorTheme === "light" ? true : false
  );
  const toggleDarkMode = (checked) => {
    setTheme(colorTheme);
    setDarkSide(checked);
    setLang(language);
  };
  useEffect(() => {}, [lang]);
  return (
    <div>
      <Navbarf
        darkSide={darkSide}
        toggleDarkMode={toggleDarkMode}
        setLang={setLang}
        lang={lang}
      />

      <Outlet context={[colorTheme, lang]} />

      <Footer dark={colorTheme} lang={lang} />
    </div>
  );
}
