import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import img1 from "./image 89 (1).svg";
import "./sign.css";
import { useOutletContext } from "react-router-dom";
import { useRegisterLeadToMutation } from "../../api/form";

const ImageSlider = () => {
  return (
    <div className="container mx-auto">
      <div>
        <img
          src={img1}
          alt="Slide 1"
          className="rounded-2xl w-full h-auto lg:object-cover lg:h-[404px]"
        />
      </div>
    </div>
  );
};

const ConsultationForm = () => {
  const [inputs, setInputs] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
  });
  const [registerLeadToCourse] = useRegisterLeadToMutation();

  const [loading, setLoading] = useState(false);
  const [dark] = useOutletContext();

  const handleInputChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;

    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const isInputValid = () => {
    return Object.values(inputs).every((input) => input.trim() !== "");
  };

  const addHandler = async (e) => {
    e.preventDefault();

    if (!isInputValid()) {
      toast.error("Please fill in all fields.");
      return;
    }

    setLoading(true);
    const token = "7108034794:AAG-2wmFN-p0DxgHsYDsVA9JpUJBGyNvIEM";
    const chatId = -4226730043;
    inputs.utm_Source = "Softclub.tj";
    inputs.utm_Campaign = "C++";
    let myText = `FirstName: ${inputs.firstName}\nSurname: ${inputs.lastName}\nEmail: ${inputs.email}\nPhone: ${inputs.phoneNumber}`;
    let url = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chatId}&text=${encodeURIComponent(
      myText
    )}`;

    const data = await registerLeadToCourse(inputs);
    console.log(data);

    await fetch(url);
    if (data?.data?.statusCode === 200) {
      toast.success(data?.data?.errors[0]);
      setInputs({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
      });
    } else {
      toast.error(data?.error?.data.errors[0]);
    }
    setLoading(false);
  };

  const { t } = useTranslation();

  return (
    <div className="mx-auto p-4 w-[-webkit-fill-available] lg:w-[580px] bg-white dark:bg-[#161F2D] font-main rounded-2xl">
      <ToastContainer />
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: "20px",
          color: dark !== "dark" ? "white" : "#262626",
          lineHeight: "28px",
          fontFamily: "Montserrat,sans-serif",
        }}
      >
        {t("home.form.header")}
      </Typography>
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: "14px",
          color: dark !== "dark" ? "white" : "#D4D4D4",
          fontFamily: "Montserrat,sans-serif",
        }}
      >
        {t("home.form.desc")}
      </Typography>
      <Box
        component="form"
        onSubmit={addHandler}
        sx={{
          mt: "10px",
          display: "flex",
          flexDirection: "column",
          gap: "12px",
        }}
      >
        <div className="flex items-center flex-col sm:flex-row gap-3">
          <input
            type="text"
            className="w-full placeholder:text-[#A3A3A3] dark:text-white dark:bg-[#091220] dark:border-[#334155] rounded-[10px] h-[56px] border-[#E2E8F0]"
            name="firstName"
            value={inputs.firstName}
            onChange={handleInputChange}
            required
            placeholder={t("home.form.name")}
          />
          <input
            type="text"
            name="lastName"
            value={inputs.lastName}
            onChange={handleInputChange}
            required
            className="w-full placeholder:text-[#A3A3A3] dark:text-white dark:bg-[#091220] dark:border-[#334155] rounded-[10px] h-[56px] border-[#E2E8F0]"
            placeholder={t("home.form.surname")}
          />
        </div>
        <input
          type="tel"
          name="phoneNumber"
          onChange={handleInputChange}
          value={inputs.phoneNumber}
          className="w-full placeholder:text-[#A3A3A3] dark:text-white dark:bg-[#091220] dark:border-[#334155] rounded-[10px] h-[56px] border-[#E2E8F0]"
          required
          placeholder={t("home.form.phone")}
        />
        <input
          type="email"
          className="w-full placeholder:text-[#A3A3A3] dark:text-white dark:bg-[#091220] dark:border-[#334155] rounded-[10px] h-[56px] border-[#E2E8F0]"
          name="email"
          onChange={handleInputChange}
          value={inputs.email}
          required
          placeholder={t("home.form.email")}
        />

        <Typography
          variant="caption"
          sx={{ color: dark !== "dark" ? "#525252" : "#737373" }}
        >
          <span>{t("home.form.politic")}</span>{" "}
          <span className="border-b border-[#A3A3A3]">
            <a href="/">{t("home.form.privacy")}</a>
          </span>
        </Typography>
        <Button
          type="submit"
          variant="contained"
          sx={{
            bgcolor: "#0284C7",
            borderRadius: "10px",
          }}
          fullWidth
          className="mt-4 sm:w-[368px] w-full"
          disabled={loading}
        >
          {loading ? "Submitting..." : t("home.form.submit")}
        </Button>
      </Box>
    </div>
  );
};

const Sign = () => {
  return (
    <div className="flex flex-col lg:flex-row px-5 items-start gap-6">
      <ConsultationForm />
      <ImageSlider />
    </div>
  );
};

export default Sign;
