import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import AboutUs from "./Containers/AboutUs/AboutUs";
import NewCoursePage from "./Containers/Courses/NewCoursePage";
import "./index.css";
import OwnLayout from "./layout/OwnLayout";
import { BlogItemPage, Blogs, HomePage } from "./routes/routes";

function App() {
  return (
    <div className="App  bg-[#F3F4F6]  dark:bg-[#091220] font-main">
      <Routes>
        <Route
          path="/"
          element={
            <Suspense
              fallback={
                <Box
                  sx={{
                    width: "100vw",
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              }
            >
              <OwnLayout />
            </Suspense>
          }
        >
          <Route
            index
            element={
              <Suspense
                fallback={
                  <Box
                    sx={{
                      width: "100vw",
                      height: "100vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                }
              >
                <HomePage />
              </Suspense>
            }
          />
          <Route
            path="course/:id"
            element={
              <Suspense
                fallback={
                  <Box
                    sx={{
                      width: "98vw",
                      height: "100vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                }
              >
                <NewCoursePage />
              </Suspense>
            }
          />
          <Route
            path="blogs"
            element={
              <Suspense
                fallback={
                  <Box
                    sx={{
                      width: "100vw",
                      height: "100vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                }
              >
                <Blogs />
              </Suspense>
            }
          />
          <Route
            path="blog/:id"
            element={
              <Suspense
                fallback={
                  <Box
                    sx={{
                      width: "100vw",
                      height: "100vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                }
              >
                <BlogItemPage />
              </Suspense>
            }
          />
          <Route
            path="AboutUs"
            element={
              <Suspense
                fallback={
                  <Box
                    sx={{
                      width: "100vw",
                      height: "100vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                }
              >
                <AboutUs />
              </Suspense>
            }
          />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
