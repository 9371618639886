import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import softclubTeam from "../../images/Header/SoftclubImage.png";
import "./AboutUs.css";
import { useTranslation } from "react-i18next";
import "./VideoPlayer.css";
import OurSucces from "../../Components/OurSucces";
import WorkPlace from "../../Components/WorkPlace";
import SulaymonovNurullah from "../../Components/SulaymonovNurullah";
import ZoirKabirov from "../../Components/ZoirKabirov";
import Teachers from "../Home/components/Teachers";

const AboutUs = () => {
  const [playing, setPlaying] = useState(false);
  const { t } = useTranslation();

  const handlePlay = () => {
    console.log(1);
    setPlaying(true);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="pt-[130px] px-2 flex  flex-col justify-between container mx-auto">
        <div className="mb-6">
          <h1 className="text-[#0284C7] text-[28px] sm:text-[52px] font-bold">
            {t("aboutUs.header")}
          </h1>
          <p className="text-[#262626] dark:text-[#FAFAFA] text-[16px] sm:text-[24px] font-medium">
            {" "}
            {t("aboutUs.text")}
          </p>
        </div>
        {playing ? (
          <div className="container m-auto">
            <ReactPlayer
              width={"100%"}
              style={{ borderRadius: "16px" }}
              url="https://youtu.be/T7PfcNQoV5o"
              playing={true}
            />
          </div>
        ) : (
          <figure>
            <div className="flex relative justify-center container items-center">
              <img
                className="w-full rounded-2xl"
                src={softclubTeam}
                alt="softclubTeam"
              />
            </div>
            <div
              onClick={handlePlay}
              className="relative mb-[-180px]  cursor-pointer top-[-80px] xs2:top-[-112px] sm:top-[-175px] md:top-[-200px] lg:top-[-274px] xl:top-[-320px] left-[50%] -translate-x-1/2 -translate-y-1/2"
            >
              <div className="play-btn-box absolute top-0">
                <div className="play-button">
                  <FontAwesomeIcon size="xl" icon={faPlay} />
                </div>
                <div className="play-btn-line1" id="line1">
                  <div className="play-btn-line2" id="line2"></div>
                </div>
              </div>
            </div>
          </figure>
        )}
      </section>
      <div
        className={`container px-2 mx-auto ${
          playing ? "mt-[280px]" : "mt-[60px]"
        }`}
      >
        <h1 className="text-[20px] dark:text-[#FAFAFA] sm:text-[28px] font-bold text-[#262626] mb-4">
          {t("aboutUs.success")}
        </h1>
        <OurSucces />
      </div>

      <section className="container mx-auto mt-[60px] px-2">
        <h1 className="text-[20px] dark:text-[#FAFAFA] sm:text-[28px] font-bold text-[#262626] mb-6">
          {t("aboutUs.company")}
        </h1>
        <WorkPlace />
      </section>

      <section className="container mx-auto mt-[60px] px-2">
        <div>
          <h1 className="text-[20px] dark:text-[#FAFAFA] sm:text-[28px] font-bold text-[#262626] mb-4">
            {t("aboutUs.group")}
          </h1>
        </div>
        <div className="grid md:grid-cols-2 gap-[28px]">
          <SulaymonovNurullah />
          <ZoirKabirov />
        </div>
        <div>
          <Teachers pageKey={"about"} />
        </div>
      </section>
    </>
  );
};

export default AboutUs;
