import { Paper } from "@mui/material";
import dengiKinteNaNomer_003557773 from "./img/daydengi.png";
import WorkPlace from "../../../Components/WorkPlace";
import { useTranslation } from "react-i18next";

const CourseSalaries = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className="container px-2 mx-auto my-[80px]">
        <Paper
          elevation={0}
          className="dark:bg-[#161F2D]"
          sx={{ pt: "24px", px: "40px", borderRadius: "16px" }}
        >
          <div className="flex flex-col-reverse md:flex-row items-start justify-between">
            <div className="max-w-[780px]">
              <h1 className="text-[24px] text-[#262626] dark:text-white font-bold">
                Средние зарплаты в сфере IT профессий
              </h1>
              <div className="flex flex-col xs2:flex-row items-center mt-7 justify-between">
                <div className="text-center">
                  <h1 className="text-[16px] dark:text-white font-semibold text-[#262626]">
                    Junior
                  </h1>
                  <p className="text-[13px] font-medium text-[#A3A3A3]">
                    1 год
                  </p>
                  <h1 className="text-[32px] font-bold text-[#0284C7]">$250</h1>
                </div>
                <div className="h-1 w-full relative top-0 xs2:top-[-30px] rounded-sm bg-[#E2E8F0]"></div>
                <div className="text-center">
                  <h1 className="text-[16px] dark:text-white font-semibold text-[#262626]">
                    Middle
                  </h1>
                  <p className="text-[13px] font-medium text-[#A3A3A3]">
                    1-3 года
                  </p>
                  <h1 className="text-[32px] font-bold text-[#0284C7]">$600</h1>
                </div>
                <div className="h-1 w-full relative top-0 xs2:top-[-30px] rounded-sm bg-[#E2E8F0]"></div>
                <div className="text-center">
                  <h1 className="text-[16px] dark:text-white font-semibold text-[#262626]">
                    Senior
                  </h1>
                  <p className="text-[13px] font-medium text-[#A3A3A3]">
                    более 3 лет
                  </p>
                  <h1 className="text-[32px] font-bold text-[#0284C7]">
                    $1500
                  </h1>
                </div>
              </div>
            </div>
            <div className="w-[240px] h-[240px]">
              <img src={dengiKinteNaNomer_003557773} alt="na alif pzh" />
            </div>
          </div>
        </Paper>
      </section>
      <section className="container ">
        <p className="header-text px-5 mb-7 font-[700] md:mb-10 text-trueGray900 dark:text-trueGray50">
          {t("home.headers.head2")}
        </p>
        <WorkPlace />
      </section>
    </>
  );
};

export default CourseSalaries;
