import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const aboutUsApi = createApi({
  reducerPath: "aboutUs",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),
  tagTypes: ["aboutUs"],
  endpoints: (builder) => ({
    aboutUs: builder.query({
      query: ({ lang }) => `Teacher/GetAllTeachers?Language=${lang}`,
    }),
  }),
});

export const { useAboutUsQuery } = aboutUsApi;
