import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const syllabusApi = createApi({
  reducerPath: "syllabusApi", // Use a different name for the reducer path
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),
  endpoints: (builder) => ({
    getAllSyllabuses: builder.query({
      query: ({ lang, id }) =>
        `Syllabus/GetAllSyllabuses?CourseId=${id}&Language=${lang}`, // Removed the full URL, as the base URL is specified
    }),
  }),
});

export const { useGetAllSyllabusesQuery } = syllabusApi;

export const { reducer } = syllabusApi;
