import React from "react";
import { useTranslation } from "react-i18next";
import Buttons from "../../../Components/Buttons";

import certification from "../../../images/certification.png";
import Hello from "../../../images/icons/Certificete.744529ae130c66c8c1b2.pdf";

const Sertificate = () => {
  const { t } = useTranslation();
  return (
    <div className="p-1 sm:p-4">
      <div className="bg-[#E2E8F0] dark:bg-[#1E293B] p-8 rounded-2xl flex flex-col sm:flex-row items-center justify-between">
        <div>
          <h2 className="text-[40px] font-bold text-[#0284C7] mb-2">
            {" "}
            {t("courses.form.home-texts-8")}
          </h2>
          <p className="text-gray-700 dark:text-[#FAFAFA] text-[16px] font-medium  mb-4">
            {t("courses.form.home-texts-9")}
          </p>
          <a href={Hello} target="_blank" rel="noreferrer">
            <div className="mt-10">
              <Buttons
                padding={"text-[16px]"}
                text={t("courses.form.home-texts-11")}
              />
            </div>
          </a>
        </div>
        <div>
          <img src={certification} alt="certificate" />
        </div>
      </div>
    </div>
  );
};

export default Sertificate;
