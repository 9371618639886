import React from "react";
import NS from "../images/NS.png";
import { useOutletContext } from "react-router-dom";
import { useTranslation } from "react-i18next";
const SulaymonovNurullah = () => {
  const [dark] = useOutletContext();
  const { t } = useTranslation();
  return (
    <div
      style={{
        background:
          dark === "dark"
            ? "linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, #FFF 100%)"
            : "linear-gradient(180deg, rgba(30, 41, 59, 0.20) 0%, #1E293B 100%)",
      }}
      className="flex flex-col  gap-6 bg-[#FFFFFF66] backdrop-blur-sm dark:bg-[#1E293B] dark:bg-opacity-50    bg-opacity-10  px-10 py-7 rounded-[16px]"
    >
      <div className=" flex flex-col sm:flex-row justify-self-start  sm:items-center gap-[28px]">
        <img
          src={NS}
          alt="Nurullah"
          loading="lazy"
          width={"100px"}
          height={"100px"}
          className="  w-[140px] h-[140px]   rounded-[50%] "
        />
        <div>
          <h3 className="font-bold text-[20px] dark:text-[#0EA5E9] leading-7 text-start text-teritary mb-[6px]">
            {t("Nurullo.header")}
          </h3>
          <div className="w-[35%] border-b-[3px] border-[#94A3B8]"></div>
          <p className="text-[#737373] font-normal text-[12px] mt-[6px] leading-4">
            {t("Nurullo.text")}
          </p>
        </div>
      </div>
      <div className="">
        <p className="font-normal text-[14px]  leading-[20px]  text-trueGray800 dark:text-trueGray100">
          {t("Nurullo.word")}
        </p>
        <p className="font-semibold text-[14px] dark:text-white italic my-3 ">
          {t("Nurullo.about")}
        </p>
        <p className="font-normal text-[14px]  mb-3 leading-[20px]  text-trueGray800 dark:text-trueGray100">
          {t("Nurullo.important")}
        </p>
        <p className="font-semibold dark:text-white text-[14px] italic  ">
          {t("Nurullo.end")}
        </p>
      </div>
    </div>
  );
};

export default SulaymonovNurullah;
