import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist/es/constants";
import { rootReducer } from "../reducers";
import { course } from "../api/course";
import { blog } from "../api/blog";
import { form } from "../api/form";
import { revieversApi } from "../api/reviewers";
import { aboutUsApi } from "../api/aboutUs";
import { syllabusApi } from "../api/syllabus";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: [
    course.reducerPath,
    blog.reducerPath,
    aboutUsApi.reducerPath,
    revieversApi.reducerPath,
    form.reducerPath,
    syllabusApi.reducerPath,
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      course.middleware,
      blog.middleware,
      aboutUsApi.middleware,
      syllabusApi.middleware,
      revieversApi.middleware,
      form.middleware
    ),
});

export let persistor = persistStore(store);
