import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const revieversApi = createApi({
  reducerPath: "revieversApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),
  tagTypes: ["reviewers"],
  endpoints: (builder) => ({
    getRevievers: builder.query({
      query: ({lang}) => `Reviewer/GetAllReviewers?Language=${lang}`,
      providesTags: ["reviewers"],
    }),
    getRevieversByPageNumber: builder.query({
      query: ({lang}) => `Reviewer/GetAllReviewers?Language=${lang}&PageNumber=2`,
      providesTags: ["reviewers"],
    }),
  }),
});

export const { useGetRevieversQuery, useGetRevieversByPageNumberQuery } = revieversApi;