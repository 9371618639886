import Slider from "react-slick";
import { Card, CardContent, Typography } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./training.css";
import Sertificate from "../../Home/components/Sertificate";
const CourseTrainings = () => {
  const cards = [
    {
      id: 1,
      text: "Изучаете новые темы и выполняете практические задания по курсу",
    },
    {
      id: 2,
      text: "Выполняете домашние задания и сдаете их на проверку",
    },
    {
      id: 3,
      text: "Общаетесь с наставником, закрепляете знания и исправляете ошибки",
    },
    {
      id: 4,
      text: "По окончанию каждой недели вы будете сдавать экзамен",
    },
  ];

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    centerMode: false,
    slidesToScroll: 1,
    arrows: false,
    className: "training-slider",
    responsive: [
      {
        breakpoint: 1024, // tablet breakpoint
        settings: {
          slidesToShow: 3,
          dots: true,
        },
      },
      {
        breakpoint: 648, // small tablet breakpoint
        settings: {
          slidesToShow: 2,
          dots: true,
        },
      },
      {
        breakpoint: 480, // phone breakpoint
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
  };
  return (
    <div>
      <section className="container mx-auto font-main px-2 mt-[80px]">
        <div>
          <h1 className="text-[28px] dark:text-white font-bold text-[#262626] mb-6">
            Как проходит обучение
          </h1>
        </div>
        <Slider {...settings}>
          {cards.map((card) => (
            <Card
              elevation={0}
              className="dark:bg-[#334155]"
              key={card.id}
              sx={{
                height: "204px",
                px: "10px",
                borderRadius: "16px",
                bgcolor: "#E2E8F0",
              }}
            >
              <CardContent>
                <Typography
                  component="h1"
                  sx={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    fontFamily: "Montserrat",
                    color: "#0EA5E9",
                    bgcolor: "#1E293B",
                    width: "48px",
                    height: "48px",
                    borderRadius: "50%",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {card.id}
                </Typography>
                <Typography
                  className="dark:text-white"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    pt: "20px",
                    fontFamily: "Montserrat",
                  }}
                  component="p"
                >
                  {card.text}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Slider>
      </section>
      <section className="container mt-10 mx-auto">
        <Sertificate />
      </section>
    </div>
  );
};

export default CourseTrainings;
