import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  transaltion: [],
};

export const slice = createSlice({
  name: "translation",
  initialState,
  reducers: {
    setTranslations(state, action) {
      //   state.value = action.payload;
      // console.log(action.payload, "actions");
      state.transaltion = action.payload;
    },
  },
});
// Action creators are generated for each case reducer function
export const { setTranslations } = slice.actions;

export default slice.reducer;
