import { combineReducers } from "@reduxjs/toolkit";
import { course } from "../api/course";
import { blog } from "../api/blog";
import translationReducer from "./translation";
import thunk from "redux-thunk";
import { form } from "../api/form";
import { revieversApi } from "../api/reviewers";
import { aboutUsApi } from "../api/aboutUs";
import { syllabusApi } from "../api/syllabus";

export const rootReducer = combineReducers({
  [course.reducerPath]: course.reducer,
  [aboutUsApi.reducerPath]: aboutUsApi.reducer,
  [syllabusApi.reducerPath]: syllabusApi.reducer,
  [revieversApi.reducerPath]: revieversApi.reducer,
  [blog.reducerPath]: blog.reducer,
  [form.reducerPath]: form.reducer,
  translation: translationReducer,
  middleware: [thunk],
});
